main{
  position: relative;
  width: 100%;
}
section{
  position: relative;
}
a {
  text-decoration: none;
  color: white;
}

a:hover,
a:active {
  color: lightgray;
}

a.link{
  color: #286bf4;
  cursor: pointer;
  pointer-events: auto;
  &:hover,
  &:active {
    color: #1c56ca;
    text-decoration: underline;
  }
}

.dont-break-out {
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.pre-wrap{
  white-space: pre-wrap; /* CSS3 */    
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */    
  white-space: -o-pre-wrap; /* Opera 7 */    
}

.mat-simple-snackbar{
  white-space: pre-wrap;
  text-align: center;
}

.section__navigation{
  position: relative;
  max-width: 600px;
  @media (min-width: 600px) {
    max-width: 960px;
  }
  margin-left: auto;
  margin-right: auto;
}

.section__width100{
  position: relative;
  overflow: hidden;
  max-width: 600px;
  @media (min-width: 600px) {
    max-width: 1680px;
  }
  @media (min-width: 1680px) {
    max-width: 1920px;
  }
  margin: 0 auto 0 auto;
  padding: 0;
}

.section__main{
  position: relative;
  max-width: 600px;
  @media (min-width: 600px) {
    max-width: 1200px;
  }
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 75px;
}

.correct-snackbar{
  font-weight: 600;
  color: rgb(21, 185, 21);
}

.button{
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  pointer-events: auto;
  padding: 16px 26px;
  text-transform: uppercase;
  color: #242424;
  border: 1px solid #999;
  background-color: white;
  font-size: 16px;
  transition: 0.3s ease box-shadow;
  box-shadow: 
    inset 0px 1px 0px #ffffff,
    inset 0px -1px 0px #ffffff,
    inset 1px 0px 0px #ffffff,
    inset -1px 0px 0px #ffffff,
    0px 0px 0px #ffffff;
  &:hover{
    color: white;
    border: 1px solid rgb(0, 0, 0);
    background-color: #286bf4;
    box-shadow: 
    inset 0px 1px 0px #ffffff,
    inset 0px -1px 0px #ffffff,
    inset 1px 0px 0px #ffffff,
    inset -1px 0px 0px #ffffff,
    0px 0px 4px #464646;
  }
}

.line, .line2{
  width: 30%;
  height: 1px;
  background-color: #286bf4;
  transition: 0.3s ease width;
}

.main-text-content{
  margin: 75px auto;
  max-width: 900px;
  pointer-events: none;
  padding: 0 10px;
  @media (min-width: 600px) {
    padding: 0 5px;
  }
  h1, h2, h3, p{
    margin: 0;
  }
  h1{
    line-height: 1.1em;
    text-transform: uppercase;
    font-size: 1.9rem;
    color: #242424;
    font-weight: 900;
    padding: 3px;
    letter-spacing: 4px;
    text-align: center;
    @media (min-width: 600px) {
      font-size: 42px;
    }
  }
  h2{
    font-size: 1.4rem;
    color: #242424;
    text-align: center;
    font-weight: 700;
    @media (min-width: 600px) {
      font-size: 28px;
    }
  }
  p{
    font-size: 18px;
    line-height: 26px;
  }
  &:hover{
    .line{
      width: 100%;
    }
  }
}

