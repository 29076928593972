.form-section{
  z-index: 1;
  position: relative;
  color: white;
  width: 100%;
  overflow: hidden;

  h1, h2, h3{
    margin: 0;
  }
  p{
    margin: 0;
    font-size: 1em;
  }
  h1{
    font-size: 1.9em;
    font-weight: 900;
  }
  h2, h3{
    font-size: 1.3em;
    font-weight: 700;
  }
  .contact-container{
    background-color: #00040c;
    color: white;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 0;
    .line{
      height: 3px;
      width: 150px;
    }
    .data-contact-container, .form-contact-container{
      position: relative;
      min-height: 850px;
      padding: 25px;
      margin: 10px;
      background-color: rgba(0, 0, 0, 0.9);
      width: 95%;
      @media (min-width: 600px) {
        width: 500px;
      }
    }
    .data-contact-container{
      text-align: center;
      .icon-container{
        background-color: #286bf4;
        padding: 15px;
        border-radius: 50%;
        img{
          height: 35px;
          width: 35px;
        }
      }
      .poczta{
        color: #286bf4;
      }
    }
    .form-contact-container{
      color: white;
      .title-container{
        margin-bottom: 20px;
      }
      button{
        margin-top: 30px;
        font-size: 18px;
        padding: 5px 30px;
        &:enabled{
          background-color: #286bf4;
          color: white;
        }
        &:disabled{
          background-color: #286cf446;
        }
      }
      mat-form-field{
        font-size: 15px;
        width: 90%;
        color: white;
        margin-bottom: 15px;
        padding: 8px 15px;
        background-color: #286cf410;
        border-radius: 23px;
      }
      mat-hint{
        color: white;
        padding-left: 20px;
      }
      mat-error{
        padding-left: 20px;
      }
      .mat-form-field {
        .mat-input-element {
          color: white;
        }
        .mat-form-field-label {
          color: rgb(163, 163, 163);
        }
        .mat-form-field-underline {
          background-color: #286bf4;
        }
        .mat-form-field-ripple {
          background-color: #286bf4;
        }
        .mat-form-field-required-marker {
          color: white;
        }
      }
      .mat-form-field.mat-focused {
        background-color: #286cf44b;
        .mat-form-field-label {
          color: #286bf4;
        }
        .mat-form-field-ripple {
          background-color: #286bf4;
        }
        .mat-form-field-required-marker {
          color: white;
        }
        .mat-input-element {
          color: white;
        }
      }
      .mat-form-field.mat-form-field-invalid {
        background-color: rgba(255, 0, 0, 0.03);
        .mat-form-field-ripple {
          background-color: #9c1111e7;
        }
      }
    }
  }
}