html, body { height: 100%; position: relative;}
body { 
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main{
  min-height: 500px;
}

@import 'styles/theme';

header{
  position: relative;
  width: 100%;
  height: 75px;
  // background-color: #37474f;
  background-color: map-get($komisgastronomia-pl-primary, 800);
  box-shadow: 0px 1px 10px #333333;
  z-index: 10;
}


@import 'styles/main';
@import 'styles/formSection';